<template>
  <b-modal
    @show="setEmail"
    id="forgotPassword"
    size="md"
    v-model="showForgotPasswordModal"
    hide-header
    centered
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    ref="forgotPassword"
  >
    <button
      type="button"
      aria-label="Close"
      class="close border-0 forgot-close"
      @click="closeModal()"
    >
      ×
    </button>
    <div class="text-center pt-3">
      <b-img
        src="../../static/img/lock.png"
        fluid
        alt="Forgot Password Image"
        class="w-50"
      />
      <h2 class="pt-4">Forgot Password?</h2>
    </div>
    <p class="pt-3 px-3">
      Forgot your password? Fintech will send password reset instruction to the
      email address associated with your account.
    </p>
    <b-input-group class="mb-4 pt-2 col-md-10 offset-md-1">
      <input
        type="text"
        class="form-control rounded"
        :class="{
          'is-invalid': $v.forgotEmail.$error,
          'is-valid': !$v.forgotEmail.$invalid
        }"
        placeholder="Email"
        v-model="forgotEmail"
        @blur="$v.forgotEmail.$touch()"
      />
      <b-form-invalid-feedback v-if="!$v.forgotEmail.required"
        >Email can't be blank</b-form-invalid-feedback
      >
      <b-form-invalid-feedback v-if="!$v.forgotEmail.email">
        Please provide a valid email address</b-form-invalid-feedback
      >
    </b-input-group>
    <b-button
      variant="primary"
      class="px-4 login-btn col-md-4 offset-md-4 rounded"
      :disabled="$v.forgotEmail.$invalid"
      @click="sendMail"
      >Send Email</b-button
    >
  </b-modal>
</template>
<script>
import Vue from 'vue'
import { required, email } from 'vuelidate/lib/validators'

export default {
  data: () => ({
    forgotEmail: ''
  }),
  props: ['showForgotPasswordModal', 'email'],
  validations: {
    forgotEmail: {
      required,
      email
    }
  },
  methods: {
    sendMail() {
      this.$http
        .post(
          '/api/reset_password',
          { email: this.forgotEmail },
          {
            handleErrors: false
          }
        )
        .then(() => {
          Vue.toasted.show('An Email is sent to reset your password.', {
            icon: 'key',
            type: 'success'
          })
        })
        .catch(error => {
          if (error.response.status === 404) {
            Vue.toasted.show("The given Email doesn't have an account here.", {
              icon: 'chain-broken',
              type: 'error'
            })
          } else if (error.response.status === 423) {
            Vue.toasted.show(
              'Your email is not verified. Please verify to reset password.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
        })
      this.closeModal()
    },
    closeModal() {
      this.$emit('closeForgotPasswordModal')
    },
    setEmail() {
      this.forgotEmail = this.email
      this.$v.forgotEmail.$reset()
    }
  }
}
</script>
<style lang="scss" scoped>
.forgot-close {
  outline: none;
}
</style>
